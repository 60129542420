import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import {
  Err,
  ProfileConnection,
  Status,
  Success,
} from "../../util/types"

// Profile Slice Interface and Initial State
export interface ProfileState {
  profile: Status<GraphQL.GetProfileQuery>,
  profileImage: string
  openModal: boolean
  isEditMode: boolean
  connections: {
    [GraphQL.Network.Facebook]?: ProfileConnection
    [GraphQL.Network.Tiktok]?: ProfileConnection
    [GraphQL.Network.Instagram]?: ProfileConnection
    [GraphQL.Network.Snapchat]?: ProfileConnection
    [GraphQL.Network.Youtube]?: ProfileConnection
  }
  loadingVerticals: boolean
  verticals: GraphQL.VerticalFragment[]
}

const initialState: ProfileState = {
  profile: "init",
  profileImage: "",
  isEditMode: false,
  openModal: false,
  loadingVerticals: false,
  verticals: [],
  connections: {},
}

// Profile Slice
export const ProfileSlice = createSlice({
  name: "ProfileSlice",
  initialState,
  reducers: {
    setProfile: (
      state,
      action: PayloadAction<Status<GraphQL.GetProfileQuery>>,
    ) => ({
      ...state,
      profile: action.payload,
    }),
    setLoadingVerticals: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      loadingVerticals: action.payload,
    }),
    setVerticals: (
      state,
      action: PayloadAction<GraphQL.VerticalFragment[]>,
    ) => ({
      ...state,
      verticals: action.payload,
    }),
    setOpenModal: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      openModal: action.payload,
    }),
    setEditMode: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      isEditMode: action.payload,
    }),
    setProfileImage: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      profileImage: action.payload,
    }),
    setConnection: (
      state,
      { payload }: PayloadAction<{network: GraphQL.Network, connection: ProfileConnection | undefined }>,
    ) => ({
      ...state,
      connections: {
        ...state.connections,
        [payload.network]: payload.connection,
      },
    }),
  },
})

export const {
  setProfile,
  setLoadingVerticals,
  setVerticals,
  setOpenModal,
  setEditMode,
  setConnection,
  setProfileImage,
} = ProfileSlice.actions
export default ProfileSlice.reducer

// Profile Slice Thunks
export const fetchProfile = (
  id: string,
) => async (
  dispatch: Dispatch,
): Promise<Err | Success<GraphQL.GetProfileQuery>> => {
  dispatch(setProfile("loading"))

  const profileResult = await API.query<
    GraphQL.GetProfileQuery,
    GraphQL.GetProfileQueryVariables
  >(GraphQL.GetProfileDocument, { id })

  dispatch(setProfile(profileResult))

  return profileResult
}

export const searchVerticals = (
  params: GraphQL.SearchVerticalsQueryVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setLoadingVerticals(true))

  const result = await API.query<
      GraphQL.SearchVerticalsQuery,
      GraphQL.SearchVerticalsQueryVariables
      >(GraphQL.SearchVerticalsDocument, params)
  if (API.isSuccess(result)) {
    dispatch(setVerticals(result.payload.searchVerticals.rows))
  }
  dispatch(setLoadingVerticals(false))
}

export const createProfile = (
  params: GraphQL.CreateProfileMutationVariables,
) => async (): Promise<string | undefined> => {
  const result = await API.mutate<GraphQL.CreateProfileMutation, GraphQL.CreateProfileMutationVariables>(
    GraphQL.CreateProfileDocument,
    params,
  )
  if (API.isSuccess(result)) {
    return result.payload?.createPersonality.id
  }
  return undefined
}

export const updateProfile = (
  params: GraphQL.EditProfileMutationVariables,
) => async (): Promise<boolean> => {
  const result = await API.mutate<GraphQL.EditProfileMutation, GraphQL.EditProfileMutationVariables>(
    GraphQL.EditProfileDocument,
    params,
  )
  return API.isSuccess(result)
}

/**
 * Set the contact information, assuming first and last name is set, in the social accounts for this
 * profile
 * @param accountIds The social accounts the profile is set for
 * @param firstName The first name
 * @param lastName The last name
 * @returns void
 */
export const updateSocialAccountContact = (
  accountIds: ProfileConnection[],
  firstName: string,
  lastName: string,
) => async (): Promise<void> => {
  // Pull all the successful pids after updating
  const pids = await accountIds.map(async (account) => {
    // Assume id is defined
    if (!account.id) return undefined

    // Create variables
    const variables: GraphQL.EditGroupAccountContactInformationMutationVariables = {
      socialAccountId: account.id,
      firstName,
      lastName,
    }
    // Make call to mutation to set name for contact
    const results = await API.updateSocialAccountContactInfo(variables)

    // Check for success and return the id
    if (API.isSuccess(results)) return results.payload?.createSocialAccountContact.id

    // It wasn't a success, return undefined
    return undefined
  })

  // Ensure everything is finished
  Promise.all(pids)
}

/**
 * Update all the emails added by team, and determine if the primary email as also set.  If primary
 * email was set, then update the primary email for each social account
 * @param accountIds The social account ids
 * @param emails The emails from the form
 * @param primaryIndex The primary email index in the emails array
 * @returns void
 */
export const updateSocialAccountEmails = (
  accountIds: ProfileConnection[],
  emails: string[],
  primaryIndex: number,
) => async (): Promise<void> => {
  // Loop through email addresses
  emails.forEach((email, idx) => {
    // Determine if primary email address
    const isPrimary = (idx === primaryIndex)

    // Extract the successful update of emails in social accounts
    const pids = accountIds.map(async (account) => {
      // Ensure account id exists
      if (account.id) {
        // Create variables for mutation call to update email in account
        const variables: GraphQL.CreateSocialAccountEmailMutationVariables = {
          emailAddress: email,
          networkAccountId: account.id,
        }

        // Make mutation call to update/add email
        const results = await API.createSocialAccountEmail(variables)

        // Check for successful update
        if (API.isSuccess(results)) {
          // Check to see if primary email address
          if (isPrimary) {
            // Create primary email mutation call variable
            const pvars: GraphQL.EditGroupAccountPrimaryEmailAddressMutationVariables = {
              socialAccountId: account.id,
              email,
            }

            // Make call to mutation to update primary email
            await API.updateSocialAccountPrimaryEmail(pvars)
          }

          // Return the id
          return results.payload?.createNetworkAccountEmail.id
        }
      }

      // ID was not set for account, return undefined
      return undefined
    })

    // Ensure everything has run
    Promise.all(pids)
  })
}

export const createPlaceholderAccount = (
  { username, network }: GraphQL.CreatePlaceholderSocialAccountMutationMutationVariables,
) => async (): Promise<Err | Success<GraphQL.CreatePlaceholderSocialAccountMutationMutation | null>> => {
  const result = await API.mutate<
  GraphQL.CreatePlaceholderSocialAccountMutationMutation,
  GraphQL.CreatePlaceholderSocialAccountMutationMutationVariables
  >(
    GraphQL.CreatePlaceholderSocialAccountMutationDocument,
    // https://influential-team.atlassian.net/browse/ID-1392
    { username: username.trim(), network },
  )
  return result
}
