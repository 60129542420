import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./group-accounts-body.sass"
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridTreeNode,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"
import { EditOutlined } from "@mui/icons-material"
import TuneIcon from "@mui/icons-material/Tune"
import LabelImportantIcon from "@mui/icons-material/LabelImportant"
import { useParams } from "react-router-dom"
import SearchBar from "../SearchBar"
import { useSelector, useDispatch } from "../../state/hooks"
import IconButton from "../IconButton"
import Dropdown from "../Dropdown"
import FilterMenu, { FilterMenuValue } from "../FilterMenu"
import Button from "../Button"
import { RootState } from "../../state/store"
import { getGroupAccounts, setGroupAccountReloadStatus } from "../../state/communicationsPage"
import * as API from "../../util/apiClient"
import { getColumnHeaders, getRowData } from "./GroupAccountsTableContent"
import GroupAccountsContactInfoModal from "../GroupAccountsContactInfoModal"
import {
  setSocialAccountId,
  setContactInfoModalOpen,
  setSocialAvatarInfo,
  resetContactInfoModal,
} from "../../state/groupAccountsContactInfoModal"

/**
 * Type for the parameter, when clicking on a cell in the table
 */
type TableDataGridParams = GridCellParams<any, unknown, unknown, GridTreeNode>

/**
 * Component to render the content for group accounts for a specific campaign and/or list of accounts
 * @returns Renders the content of the group accounts
 */
export default function GroupAccountsBody() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.GroupAccountsBody" })
  const dispatch = useDispatch()
  const { commGroupID } = useParams()
  const [ searchInputValue, setSearchInputValue ] = useState("")
  const [ filterValue, setFilterValue ] = useState({} as FilterMenuValue)
  const { communicationGroupAccounts, relaodGroupAccounts: reload } = useSelector((root: RootState) => root.communicationsPage)
  const tableHeaders: GridColDef[] = getColumnHeaders(translate)

  /**
   * Function to load the group accounts for this particular communication group
   */
  useEffect(() => {
    // Check to see if reloading the content after updating contact information
    if (reload) {
      dispatch(setGroupAccountReloadStatus(false))
      dispatch(getGroupAccounts({
        startsWith: searchInputValue,
        communicationGroupId: `${ commGroupID }`,
      }))
    }
  }, [ searchInputValue, filterValue, reload ])

  /**
   * Update the state value for search input, which should trigger a reload of the group accounts
   * @param event The element that contains the information on the search bar
   */
  const onSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Placeholder
    setSearchInputValue(event.target.value)
    dispatch(setGroupAccountReloadStatus(true))
  }

  const onEditClick = () => {
    // Placeholder
  }

  const onFilterClick = () => {
    // Placeholder
  }

  const onAddAccountClick = () => {
    // Placeholder
  }

  /**
   * Handles any clicks on cells in the table
   * @param param0 The cell and row that was clicked on in the table
   */
  const cellClickAction = ({ field, row }: TableDataGridParams) => {
    // Check to see if the contact info column was clicked on
    if (field === "contactInfo") {
      // Reset the form
      dispatch(resetContactInfoModal())

      // Set account id
      dispatch(setSocialAccountId(row.account.id))

      // Set the social avatar information
      dispatch(setSocialAvatarInfo({
        ...row.account,
      }))

      // Display the modal
      dispatch(setContactInfoModalOpen(true))
    }
  }

  // Pull in all the rows from the graphql query
  const rows: (GridValidRowModel | null)[] = (API.isSuccess(communicationGroupAccounts))
    ? communicationGroupAccounts.payload.searchCommunicationGroupNetworkAccount.rows.map((row) => getRowData(row)) : []

  // Return the JSX elements for this page
  return (
    <div className="cp_component_communications-body-main-container">
      <div className="header-section">
        <SearchBar className="search-bar" onChange={ (event) => onSearchInput(event) } />
        <div className="details-container">
          <IconButton onClick={ onFilterClick }>
            <TuneIcon />
          </IconButton>
          <Dropdown
            buttonType="custom"
            customButtonChildren={ <LabelImportantIcon className="important-label-button" /> }
          >
            <FilterMenu
              filterOptions={ [] }
              filterValue={ filterValue }
              setFilterValue={ setFilterValue }
            />
          </Dropdown>
          <IconButton onClick={ onEditClick }>
            <EditOutlined />
          </IconButton>
          <Button
            className="add-account-button"
            label={ translate("+ ADD ACCOUNT") }
            onClick={ onAddAccountClick }
          />
        </div>
      </div>
      <div className="communications-body">
        <DataGridPro
          checkboxSelection={ true }
          columns={ tableHeaders }
          disableRowSelectionOnClick={ true }
          hideFooter={ true }
          rowHeight={ 80 }
          rows={ rows }
          loading={ (communicationGroupAccounts === "init" || communicationGroupAccounts === "loading") }
          pinnedColumns={ {
            left: [ "__check__", "account" ],
            right: [ "groupsContextMenu" ],
          } }
          onCellClick={ cellClickAction }
        />
      </div>
      <GroupAccountsContactInfoModal />
    </div>
  )
}
