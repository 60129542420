import React, { useEffect, useMemo } from "react"
import { UploadFileRounded } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { Container } from "@mui/material"
import { useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import CommunicationMessagesEmpty from "./CommunicationMessagesEmpty"
import Avatar from "../Avatar"
import { formatTimestampToCustomDate, limitTextLength } from "../../util/miscHelper"

import "./communications-reading-panel-view.sass"
import { Scope } from "../../util/types"

interface ConversationData {
  hasMessages: boolean,
  messages: GraphQL.GetConversationQuery["getConversation"]["conversationThread"]["messages"],
  subject: GraphQL.GetConversationQuery["getConversation"]["subject"],
  socialAccount: GraphQL.GetConversationQuery["getConversation"]["socialAccount"] | null,
}

interface CommMessageProps {
  message: ConversationData["messages"][0],
  socialAccount: ConversationData["socialAccount"],
  indx: number,
  totalMessages: number,
}

function CommMessage({
  message, socialAccount, indx, totalMessages,
}: CommMessageProps) {
  const [ showFullText, setShowFullText ] = React.useState(false)
  const fromName = message.outgoing ? message.from.alias : socialAccount?.userName
  const imageUrl = message.outgoing
    ? undefined
    : socialAccount?.profilePictureUrl || undefined
  const fromEmail = message.from.address
  const { created } = message
  const to = message.to.length ? message.to.map((toEmail) => toEmail.address).join(", ") : ""
  const cc = message.cc.length ? message.cc.map((ccEmail) => ccEmail.address).join(", ") : ""
  const bodyText = message.text

  const shouldShowFullText = () => {
    if (totalMessages === 1) return true
    if (totalMessages === 2 && indx === 1) return true
    return false
  }

  useEffect(() => {
    setShowFullText(shouldShowFullText())
  }, [ totalMessages, indx ])

  return (
    <div
      key={ message.id }
      className="cp_ct-thread single-message"
    >
      <div className="cp_ct-thread-message-content">
        <div
          className="cp_ct-thread-message-content-top"
          onClick={ () => { setShowFullText(!showFullText) } }
          onKeyDown={ (e) => {
            if (e.key === "Enter" || e.key === " ") {
              setShowFullText(!showFullText)
            }
          } }
          role="button"
          tabIndex={ 0 }
        >
          <Avatar size="sm" src={ imageUrl } className="cp_ct-thread-avatar">
            { fromName ? fromName.charAt(0) : "" }
          </Avatar>

          <p className="cp_ct-thread-handle-name">
            { fromName }
          </p>
          <p className="cp_ct-thread-message-address">{ fromEmail }</p>
          <p className="cp_ct-thread-date">{ formatTimestampToCustomDate(created) }</p>
        </div>
        <p className={ `cp_ct-thread-to ${ cc ? "has-cc" : "" }` }>{ `to ${ to }` }</p>
        { cc && <p className="cp_ct-thread-cc">{ `cc ${ cc }` }</p> }
        { showFullText
          ? <p className="cp_ct-thread-body">{ bodyText }</p>
          : <p className="cp_ct-thread-body shorten">{ limitTextLength(bodyText, 140) }</p>
        }
      </div>
    </div>
  )
}

export default function CommunicationsMessages() {
  const { communicationMessages } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)
  const { scopes } = useSelector(({ user }) => user)
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.CommunicationsMessages" })

  const mappedConversations = useMemo((): ConversationData => {
    if (API.isSuccess(communicationMessages)) {
      return {
        hasMessages: true,
        messages: communicationMessages.payload.getConversation.conversationThread.messages,
        subject: communicationMessages.payload.getConversation.subject,
        socialAccount: communicationMessages.payload.getConversation.socialAccount,
      }
    }
    return {
      hasMessages: false,
      messages: [],
      subject: "",
      socialAccount: null,
    }
  }, [ communicationMessages ])

  if (communicationMessages === "loading") {
    return (
      <div className="communications-email-list-view loading">
        <LoadingIndicatorCard />
      </div>
    )
  }

  if (communicationMessages === "init" || mappedConversations.hasMessages === false) {
    return (
      <div id="cp_comm-messages-empty" className="communications-email-list-view">
        <CommunicationMessagesEmpty parentIdName="cp_comm-messages-empty" />
      </div>
    )
  }

  return (
    <div className="communications-email-list-view cp_communications-thread cp_ct">
      <div className="cp_ct-thread-header">
        { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
          <Container className="create-new-deliverable-button">
            <>
              <UploadFileRounded />
              <p>{ translate("Create New Deliverable") }</p>
            </>
          </Container>
        ) }
      </div>
      <h2 className="cp_ct-subject">{ mappedConversations.subject }</h2>
      { mappedConversations.hasMessages && (
        mappedConversations.messages
          .slice()
          .reverse()
          .map((message, indx) => (
            <CommMessage
              key={ message.id }
              message={ message }
              socialAccount={ mappedConversations.socialAccount }
              indx={ indx }
              totalMessages={ mappedConversations.messages.length }
            />
          ))
      ) }
    </div>
  )
}
