import React from "react"
import { TFunction } from "i18next"
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro"
import daysjs from "dayjs"
import { Chip } from "@mui/material"
import { useSelector } from "react-redux"
import SocialAvatar from "../SocialAvatar"
import * as Constant from "../../util/constant"
import { CommunicationGroupNetworkAccountLabel } from "../../graphql"
import GroupAccountsTableContextMenu from "./GroupAccountsTableContextMenu"
import { Scope } from "../../util/types"

export function getColumnHeaders(translate: TFunction): GridColDef[] {
  const { scopes } = useSelector(({ user }) => user)

  // Check to see if social account contact info is not in scope
  if (!scopes.includes(Scope.SOCIAL_ACCOUNT_CONTACT_INFO)) {
    // Return the header information without the contact info column
    return [
      {
        field: "account",
        headerName: translate("ACCOUNT"),
        sortable: false,
        renderCell: (params) => (
          <SocialAvatar { ...params.row.account } />
        ),
        disableColumnMenu: true,
        resizable: false,
        width: 275,
      },
      {
        field: "labels",
        headerName: translate("LABELS"),
        sortable: false,
        renderCell: (params) => (
          <div>
            { params.row.labels.forEach((label: CommunicationGroupNetworkAccountLabel) => {
              <Chip label={ label.label } onDelete={ () => { } } />
            }) }
          </div>
        ),
        disableColumnMenu: true,
        resizable: false,
        width: 350,
      },
      {
        field: "addedToGroup",
        headerName: translate("ADDED TO GROUP"),
        sortable: false,
        renderCell: (params) => (
          <p className="body_medium">{ params.row.addedToGroup }</p>
        ),
        disableColumnMenu: true,
        resizable: false,
        width: 200,
      },
      {
        field: "groupsContextMenu",
        headerName: "",
        sortable: false,
        renderCell: (params) => (
          <GroupAccountsTableContextMenu params={ params } />
        ),
        disableColumnMenu: true,
        width: 50,
      },
    ]
  }

  // Return the header information
  return [
    {
      field: "account",
      headerName: translate("ACCOUNT"),
      sortable: false,
      renderCell: (params) => (
        <SocialAvatar { ...params.row.account } />
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 275,
    },
    {
      field: "contactInfo",
      headerName: translate("CONTACT INFO"),
      sortable: false,
      renderCell: (params) => (
        <div>
          <p className="body_medium">
            { params.row.contactInfo.firstName }
            &nbsp;
            { params.row.contactInfo.lastName }
          </p>
          <p className="body_small">
            { params.row.contactInfo.email }
          </p>
        </div>
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 275,
    },
    {
      field: "labels",
      headerName: translate("LABELS"),
      sortable: false,
      renderCell: (params) => (
        <div>
          { params.row.labels.forEach((label: CommunicationGroupNetworkAccountLabel) => {
            <Chip label={ label.label } onDelete={ () => { } } />
          }) }
        </div>
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 350,
    },
    {
      field: "addedToGroup",
      headerName: translate("ADDED TO GROUP"),
      sortable: false,
      renderCell: (params) => (
        <p className="body_medium">{ params.row.addedToGroup }</p>
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 200,
    },
    {
      field: "groupsContextMenu",
      headerName: "",
      sortable: false,
      renderCell: (params) => (
        <GroupAccountsTableContextMenu params={ params } />
      ),
      disableColumnMenu: true,
      width: 50,
    },
  ]
}

export function getRowData(row: { [ k: string ]: any }): GridValidRowModel | null {
  // Extract the social account and the statistics
  const { socialAccount } = row
  const { socialAccountStatistics: stats } = socialAccount

  // Create the data for this row
  return {
    id: row.id,
    account: {
      id: socialAccount.id,
      followers: stats.followers,
      fullName: socialAccount.name || "",
      isBlacklisted: socialAccount.personality?.blacklist || false,
      isNSFW: row.isNsfw,
      profilePictureUrl: socialAccount.profilePictureUrl,
      username: socialAccount.userName,
      network: socialAccount.network,
      isPlaceholder: socialAccount.isPlaceholder,
    },
    contactInfo: {
      firstName: socialAccount.contact?.firstName || "First Name",
      lastName: socialAccount.contact?.lastName || "Last Name",
      email: socialAccount.primaryEmail?.address || "No Prmary Email",
    },
    labels: row.labels,
    addedToGroup: daysjs.unix(row.created).format(Constant.MONTH_DAY_ABBR_FORMAT),
  }
}
