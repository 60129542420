import React from "react"

import { useTranslation } from "react-i18next"

import "./post-preview.sass"
import { useSelector } from "../../state/hooks"

function AssignReviewer(): React.JSX.Element {
  const {
    deliverable,
  } = useSelector(({ campaignDeliverableContentModal }) => campaignDeliverableContentModal)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContent" })

  if (!deliverable?.deliverableMedia || deliverable.deliverableMedia.length === 0) return <> </>

  return (
    <div className="cp_component_modal-campaign-deliverable-assignreviewer">
      <p className="body_large">{ translate("Current Reviewer Group") }</p>
      <div className="cp_component_modal-campaign-deliverable-assignreviewer-body">
        <span>{ translate("NoReviewerGroupAssigned") }</span>
      </div>
    </div>
  )
}

export default AssignReviewer
