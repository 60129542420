import React, {
  JSX,
  useEffect,
  useMemo,
} from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import * as API from "../../util/apiClient"
import { useDispatch, useSelector } from "../../state/hooks"
import Input from "../Input"
import Modal from "../Modal"
import Select from "../Select"
import MenuItem from "../MenuItem"
import Checkbox from "../Checkbox"
import Divider from "../Divider"
import CommGroupEmailsAutocomplete, { NetworkInfo } from "./CommGroupEmailsAutocomplete"
import { Toast } from "../../util/types"
import { pushToast } from "../../state/toastSlice"
import {
  fetchCommTemplates,
  setModalCreateMessageOpen,
  createCommSubmission,
  resetCommSubmission,
} from "../../state/modalCreateMessageSlice"
import RichTextEditor from "../RichTextEditor"

import "./style.sass"

export default function ModalCreateMessage(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCreateMessage" })
  const { commGroupID } = useParams()
  const [ selectedTemplate, setSelectedTemplate ] = React.useState("")
  const [ isContentUploadLink, setIsContentUploadLink ] = React.useState(false)
  const [ subject, setSubject ] = React.useState("")
  const [ commTo, setCommTo ] = React.useState<NetworkInfo[] | []>([])
  const [ commCCEmails, setCommCCEmails ] = React.useState<NetworkInfo[] | []>([])
  const [ showCommCC, setShowCommCC ] = React.useState(false)
  const [ editorContent, setEditorContent ] = React.useState("")

  const dispatch = useDispatch()
  const {
    modalCreateMessageOpen, commTemplates, commSubmisssion,
  } = useSelector(({ ModalCreateMessageSlice }) => ModalCreateMessageSlice)

  useEffect(() => {
    if (!API.isSuccess(commTemplates)) {
      dispatch(fetchCommTemplates())
    }
  }, [ commTemplates ])

  const clearForm = () => {
    setSubject("")
    setCommTo([])
    setCommCCEmails([])
    setEditorContent("")
    setIsContentUploadLink(false)
  }

  useEffect(() => {
    let toast: Toast
    if (API.isSuccess(commSubmisssion)) {
      toast = {
        type: "success",
        message: translate("Success! Message sent."),
      }
      dispatch(pushToast(toast))
      clearForm()
      dispatch(resetCommSubmission())
      dispatch(setModalCreateMessageOpen(false))
    } else if (API.isError(commSubmisssion)) {
      toast = {
        type: "error",
        message: translate("Failed to create message. try again later."),
      }
      dispatch(pushToast(toast))
      clearForm()
      dispatch(resetCommSubmission())
      dispatch(setModalCreateMessageOpen(false))
    }
  }, [ commSubmisssion ])

  const handleCancelAction = () => {
    dispatch(setModalCreateMessageOpen(false))
  }

  const getRecipients = () => commTo.map((recipient) => ({
    ccEmailAddresses: commCCEmails.map((cc) => cc.email),
    socialAccountId: recipient.socialAccountId,
    toEmailAddress: recipient.email,
  }))

  const handleCreateMessageSubmission = () => {
    if (commGroupID) {
      const messageInfo = {
        subject,
        enableUploadLink: isContentUploadLink,
        attachments: [],
        body: editorContent,
        communicationGroupId: commGroupID,
        recipients: getRecipients(),
      }
      dispatch(createCommSubmission(messageInfo))
    }
  }

  const handleShowCommCC = () => {
    setShowCommCC(!showCommCC)
  }

  const handleTemplateChange = (templateId: string) => {
    setSelectedTemplate(templateId)
    // filter template and add to editor
    if (API.isSuccess(commTemplates)) {
      const { getCommunicationTemplates } = commTemplates.payload
      if (getCommunicationTemplates) {
        const currTemplate = getCommunicationTemplates.find((template) => template.id === templateId)
        if (currTemplate) {
          setEditorContent(currTemplate.body)
        }
      }
    }
  }

  const submissionIsActive = useMemo(() => (commTo.length > 0
      && subject.length > 0
      && editorContent.length > 0
  ), [ commTo, subject, editorContent ])

  return (
    <Modal
      title={ translate("Create New Message") }
      disabled={ !submissionIsActive }
      primaryLabel={ translate("SEND") }
      open={ modalCreateMessageOpen }
      closeAction={ handleCancelAction }
      hasSecondaryButton={ false }
      primaryAction={ handleCreateMessageSubmission }
      maxWidth="lg"
      className="cp_modal-comm-create-message"
    >
      <section className="cp_modal-comm-create-message-template">
        <h5>{ `1. ${ translate("Select a template") } ` }</h5>
        <div className="cp_modal-comm-create-message-template-select">
          <p>{ translate("Template") }</p>
          <div className="top-fields-container">
            { API.isSuccess(commTemplates) && (
            <Select
              id="commTemplateSelect"
              className="comm-template-select"
              label=""
              labelId="modal-manage-brand-managers-label"
              fullWidth={ true }
              value={ selectedTemplate || "" }
              onChange={ (e) => handleTemplateChange(e.target.value) }
              menuItems={ commTemplates.payload?.getCommunicationTemplates.map(
                (template) => <MenuItem key={ template.id } value={ template.id }>{ template.name }</MenuItem>,
              )
            }
            />
            ) }
            <div className="upload-link-container">
              <Checkbox
                id="commTemplateCheckbox"
                color="primary"
                checked={ isContentUploadLink }
                onChange={ () => setIsContentUploadLink(!isContentUploadLink) }
              />
              <label htmlFor="commTemplateCheckbox">{ translate("Content Upload Link") }</label>
            </div>
          </div>
        </div>
      </section>
      <Divider />
      <section className="cp_modal-comm-create-message-configure">
        <h5>{ `2. ${ translate("Configure Email") } ` }</h5>
        <div className="cp_modal-comm-create-message-configure-fields">
          <p className="labelfor-to">{ `${ translate("To") }:` }</p>
          { commGroupID && (
          <CommGroupEmailsAutocomplete
            communicationGroupId={ commGroupID }
            setSelectedEmails={ setCommTo }
            selectedEmails={ commTo }
            setShowCommCC={ handleShowCommCC }
          />
          ) }
          { (commGroupID && showCommCC) && (
            <>
              <p>CC:</p>
              <CommGroupEmailsAutocomplete
                communicationGroupId={ commGroupID }
                setSelectedEmails={ setCommCCEmails }
                selectedEmails={ commCCEmails }
                setShowCommCC={ null }
              />
            </>
          ) }
          <Input
            className="cp_modal-comm-create-message-subject"
            id="commCC"
            label=""
            placeholder="Subject Line:"
            fullWidth={ true }
            value={ subject }
            onChange={ (e) => setSubject(e.target.value) }
          />
          <RichTextEditor editorContent={ editorContent } setEditorContent={ setEditorContent } />
        </div>
      </section>
    </Modal>
  )
}
