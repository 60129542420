import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { EditNote } from "@mui/icons-material"

import Button from "../Button"
import IconButton from "../IconButton"
import NetworkAvatar from "../NetworkAvatar"
import StatusDeliverable from "../StatusDeliverable"
import { DeliverableStatus } from "../../graphql"
import { Scope } from "../../util/types"
import { setEdittingUrl } from "../../state/campaignDeliverableContentModalSlice"
import { shorthandNumber } from "../../util/miscHelper"
import { useDispatch, useSelector } from "../../state/hooks"
import Select from "../Select"
import MenuItem from "../MenuItem"

import "./overview.sass"

function Overview(): React.JSX.Element {
  const {
    deliverable,
    edittingUrl,
  } = useSelector(({ campaignDeliverableContentModal }) => campaignDeliverableContentModal)
  const {
    scopes,
  } = useSelector(({ user }) => user)

  const dispatch = useDispatch()

  const isTieredApproval = scopes.includes(Scope.CAMPAIGN_MANAGEMENT && Scope.DEVELOPMENT)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContent" })

  if (!deliverable) return <> </>

  const filterOptions = useMemo(() => [
    {
      label: translate("Approve"), value: "Approve",
    },
    {
      label: translate("Approve with Suggestions"), value: "Approve with suggestions",
    },
    {
      label: translate("Request Revision"), value: "Request Revision",
    },
  ].map(({ label, value }) => (
    <MenuItem
      id={ `cp_dashboard-campaigns_component-header-filter-option-${ value }` }
      key={ label }
      onClick={ async () => null }
      value={ value }
    >
      { translate(label) }
    </MenuItem>
  )), [ translate ])

  return (
    <div className="cp_deliverable-content-overview_component">
      <div className="cp_deliverable-content-overview_component-left">
        <NetworkAvatar
          size="lg"
          network={ deliverable.campaignNetworkAccount.socialAccount.network }
          src={ deliverable.campaignNetworkAccount.socialAccount.profilePictureUrl }
        >
          { deliverable.campaignNetworkAccount.socialAccount.userName.charAt(0) }
        </NetworkAvatar>
        <div className="cp_deliverable-content-overview_component-left-details">
          <div>
            <p className="cp_deliverable-content-overview_component-left-details-name">
              { `@${ deliverable.campaignNetworkAccount.socialAccount.userName }` }
            </p>
          </div>
          <p className="cp_deliverable-content-overview_component-left-details-demographics">
            {
              [
                deliverable.campaignNetworkAccount.socialAccount.name,
                `${ shorthandNumber(deliverable.campaignNetworkAccount.socialAccount.socialAccountStatistics.followers) }
                ${ translate("FOLLOWERS") }`,
              ].filter((components) => !!components).join(" | ")
            }
          </p>
        </div>
      </div>
      { !isTieredApproval ? (
        <div className="cp_deliverable-content-overview_component-right">
          <StatusDeliverable deliverableStatus={ deliverable.contentStatus } />
          { deliverable.contentStatus === DeliverableStatus.Live && scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
          <IconButton
            variant={ edittingUrl ? "filled" : "outlined" }
            onClick={ () => dispatch(setEdittingUrl(!edittingUrl)) }
          >
            <EditNote />
          </IconButton>
          ) }
        </div>
      ) : (
        <div className="cp_deliverable-content-overview_component-review">
          <Select
            id="deliverable-review-status"
            label=""
            labelId="select-deliverable-status"
            menuItems={ filterOptions }
            className="deliverable-select"
            value="Approve"
          />
          <Button
            label={ translate("Finalize Feedback") }
            variant="outlined"
            isPrimary={ false }
            className="cp_deliverable-content-overview_component-finalizeFeedback"
            onClick={ async () => null }
          />
        </div>
      ) }
    </div>
  )
}

export default Overview
