import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"
import * as API from "../../util/apiClient"

type SocialAvatarProps = {
  id: string
  followers: number,
  fullName: string,
  isBlacklisted: boolean,
  isNSFW: boolean,
  profilePictureUrl: string,
  username: string,
  isPlaceholder: boolean,
  isUnsubscribed: boolean,
  network: GraphQL.Network,
}

export interface ContactInfoModalState {
  GAContactInfoSocialAvatarInfo: SocialAvatarProps
  GASocialAccountId: string
  GAContactInfoModalOpen: boolean
  GAContactInfo: Status<GraphQL.GetGroupAccountContactInfoForEditQuery>
  GAContactInfoUpdated: Status<GraphQL.EditGroupAccountContactInformationMutation | null>
  GAPrimaryEmailUpdated: Status<GraphQL.EditGroupAccountPrimaryEmailAddressMutation | null>
}

const initialState: ContactInfoModalState = {
  GAContactInfoSocialAvatarInfo: {
    followers: 0,
    fullName: "Full Name",
    profilePictureUrl: "",
    username: "Username",
    id: "",
    isBlacklisted: false,
    isNSFW: false,
    isPlaceholder: false,
    isUnsubscribed: false,
    network: GraphQL.Network.Facebook,
  },
  GASocialAccountId: "",
  GAContactInfoModalOpen: false,
  GAContactInfo: "init",
  GAContactInfoUpdated: "init",
  GAPrimaryEmailUpdated: "init",
}

export const ContactInfoModalSlice = createSlice({
  name: "ContactInfoModal",
  initialState,
  reducers: {
    setSocialAvatarInfo: (
      state,
      action: PayloadAction<SocialAvatarProps>,
    ) => ({
      ...state,
      GAContactInfoSocialAvatarInfo: action.payload,
    }),
    setSocialAccountId: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      GASocialAccountId: action.payload,
    }),
    setContactInfoModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      GAContactInfoModalOpen: action.payload,
    }),
    setContactInfo: (
      state,
      action: PayloadAction<Status<GraphQL.GetGroupAccountContactInfoForEditQuery>>,
    ) => ({
      ...state,
      GAContactInfo: action.payload,
    }),
    setUpdatedContactInfo: (
      state,
      action: PayloadAction<Status<GraphQL.EditGroupAccountContactInformationMutation | null>>,
    ) => ({
      ...state,
      GAContactInfoUpdated: action.payload,
    }),
    setUpdatedPrimaryEmail: (
      state,
      action: PayloadAction<Status<GraphQL.EditGroupAccountPrimaryEmailAddressMutation | null>>,
    ) => ({
      ...state,
      GAPrimaryEmailUpdated: action.payload,
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetContactInfoModal: (state) => initialState,
  },
})

export const {
  setSocialAvatarInfo,
  setSocialAccountId,
  setContactInfoModalOpen,
  setContactInfo,
  setUpdatedContactInfo,
  setUpdatedPrimaryEmail,
  resetContactInfoModal,
} = ContactInfoModalSlice.actions

export default ContactInfoModalSlice.reducer

/**
 * Query to get the contact information to fill in the form
 * @param socialAccountId The ID for the social account
 * @returns The required information for the social account contact info edit form
 */
export const getGroupAccountContactInfo = (socialAccountId: string) => async (
  dispatch: Dispatch,
) => {
  // Indicate loading status
  dispatch(setContactInfo("loading"))

  // Perform the query
  const results = await API.getGroupAccountContactInfoForEdit({
    socialAccountId,
  })

  // Post results
  dispatch(setContactInfo(results))
}

/**
 * This will update the social account contact information in the database
 * @param params The mutation variables required for update along with succes and failure callbacks
 * @returns void
 */
export const updateGroupAccountContactInfo = (
  params: {
    variables: GraphQL.EditGroupAccountContactInformationMutationVariables,
    onSuccess: () => void,
    onError: () => void,
  },
) => async (dispatch: Dispatch) => {
  // Set the state
  dispatch(setUpdatedContactInfo("loading"))

  // Perform mutation
  const results = await API.updateSocialAccountContactInfo(params.variables)

  if (API.isSuccess(results)) params.onSuccess()
  else if (API.isError(results)) params.onError()

  // Set the results
  dispatch(setUpdatedContactInfo(results))
}

/**
 * Updates the database primary email address for a social account
 * @param params The primary email address and callbacks for successful or error
 * @returns void
 */
export const updateGroupAccountPrimaryEmail = (
  params: {
    variables: GraphQL.EditGroupAccountPrimaryEmailAddressMutationVariables,
    onSuccess: () => void,
    onError: () => void,
  },
) => async (dispatch: Dispatch) => {
  // Indicate that the process of update has started
  dispatch(setUpdatedPrimaryEmail("loading"))

  // Call mutation function to update the email
  const results = await API.updateSocialAccountPrimaryEmail(params.variables)

  // Determine if a success update occurred
  if (API.isSuccess(results)) params.onSuccess()
  else if (API.isError(results)) params.onError()

  // Indicate that the results are complete
  dispatch(setUpdatedPrimaryEmail(results))
}
