import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import WebAssetIcon from "@mui/icons-material/WebAsset"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material"
import { ArrowDropDown } from "@mui/icons-material"
import { useDispatch, useSelector } from "../../state/hooks"
import Modal from "../Modal"
import { RootState } from "../../state/store"
import {
  getGroupAccountContactInfo,
  setContactInfoModalOpen,
  updateGroupAccountContactInfo,
  resetContactInfoModal,
  updateGroupAccountPrimaryEmail,
} from "../../state/groupAccountsContactInfoModal"
import SocialAvatar from "../SocialAvatar"
import * as API from "../../util/apiClient"
import Input from "../Input"
import Dropdown from "../Dropdown"
import Button from "../Button"
import "./index.sass"
import { Toast } from "../../util/types"
import { pushToast } from "../../state/toastSlice"
import { setGroupAccountReloadStatus } from "../../state/communicationsPage"

/**
 * This handles the creation and management of the contact information for a particular row in the communications
 * group accounts table
 * @returns The edit contact information modal dialog
 */
export default function GroupAccountsContactInfoModal() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.GroupAccountsContactInfoModal" })
  const dispatch = useDispatch()

  // Extract all variables from the contact info state
  const {
    GAContactInfoSocialAvatarInfo: socialAvatarInfo,
    GASocialAccountId: socialAccountId,
    GAContactInfoModalOpen: open,
    GAContactInfo,
  } = useSelector((root: RootState) => root.contactInfoModal)

  // Create and initialize local state
  const [ primaryEmail, setPrimaryEmail ] = useState("")
  const [ firstName, setFirstName ] = useState("")
  const [ lastName, setLastName ] = useState("")

  /**
   * Clear all the form values
   */
  const clearForm = () => {
    setPrimaryEmail("")
    setFirstName("")
    setLastName("")
  }

  /**
   * Call GraphQL query to load the all the contact information and needed values for this modal
   */
  useEffect(() => {
    if (socialAccountId !== "") {
      clearForm()
      dispatch(getGroupAccountContactInfo(socialAccountId))
    }
  }, [ open, socialAccountId ])

  /**
   * Update local state variables with information from GraphQL query above
   */
  useEffect(() => {
    if (API.isSuccess(GAContactInfo)) {
      // Pull out the social account
      const { socialAccount: account } = GAContactInfo.payload

      setFirstName((account.contact?.firstName) ? account.contact.firstName : "")
      setLastName((account.contact?.lastName) ? account.contact.lastName : "")
      setPrimaryEmail((account.primaryEmail?.address) ? account.primaryEmail.address : "")
    }
  }, [ GAContactInfo ])

  /**
   * Clears the form and closes the modal
   */
  const onClose = () => {
    // Close the modal window
    dispatch(resetContactInfoModal())
    clearForm()
    dispatch(setContactInfoModalOpen(false))
  }

  /**
   * Updates the first name, last name, and primary email address in the database
   */
  const handleUpdate = async () => {
    // Close the modal
    dispatch(setContactInfoModalOpen(false))

    // Validate that there is something to update
    if ((firstName === "" && lastName === "" && primaryEmail === "")
        || (firstName !== "" && lastName === "" && primaryEmail === "")
        || (firstName === "" && lastName !== "" && primaryEmail === "")
    ) {
      const toast: Toast = {
        type: "warning",
        message: translate("No update was made, first/last name are required and/or primary email!"),
      }
      dispatch(pushToast(toast))
    }

    // Check to see if the first and last name have a value
    if (firstName !== "" && lastName !== "") {
      // Update the database
      await dispatch(updateGroupAccountContactInfo({
        variables: {
          socialAccountId,
          firstName,
          lastName,
        },
        onSuccess: async () => {
          // Create and submit toast
          const toast: Toast = {
            type: "success",
            message: translate("Group account contact information has been successfully updated!"),
          }
          dispatch(pushToast(toast))
        },
        onError: async () => {
          // Create and submit toast
          const toast: Toast = {
            type: "error",
            message: translate("Failed to update group account contact information!"),
          }
          dispatch(pushToast(toast))
        },
      }))
    }

    // Check to see if there is a primary email to update
    if (primaryEmail !== "") {
      await dispatch(updateGroupAccountPrimaryEmail({
        variables: {
          socialAccountId,
          email: primaryEmail,
        },
        onSuccess: async () => {
          // Create and submit toast
          const toast: Toast = {
            type: "success",
            message: translate("Group account contact primary email address has been successfully updated!"),
          }
          dispatch(pushToast(toast))
        },
        onError: async () => {
          // Create and submit toast
          const toast: Toast = {
            type: "error",
            message: translate("Failed to update group account primary email address!"),
          }
          dispatch(pushToast(toast))
        },
      }))
    }

    // Reset modal and clear the form
    clearForm()
    dispatch(resetContactInfoModal())

    // Reload the main page
    dispatch(setGroupAccountReloadStatus(true))
  }

  const editEmailsByYourTeam = () => {
    // Placeholder
  }

  /**
   * This creates the JSX to render the contact info modal dialog for communication group accounts
   * @returns The modal dialog
   */
  const render = () => {
    // Check to see if successful
    if (API.isSuccess(GAContactInfo)) {
      // Extract the social account
      const { socialAccount: account } = GAContactInfo.payload

      // Create the social avatar from content
      const avatarAccount = {
        id: account.id,
        followers: account.socialAccountStatistics.followers,
        fullName: account.name || "",
        isBlacklisted: account.personality?.blacklist || false,
        isNSFW: account.isNsfw,
        profilePictureUrl: account.profilePictureUrl,
        username: account.userName,
        isPlaceholder: account.isPlaceholder,
        isUnsubscribed: false,
        network: account.network,
      }

      // Create/return the JSX elemnts
      return (
        <Modal
          className="cp_component_group_accounts_contact_info_modal"
          open={ open }
          customTitle={ <SocialAvatar { ...avatarAccount } /> }
          closeAction={ onClose }
          primaryLabel={ translate("SAVE") }
          secondaryLabel={ translate("CLOSE") }
          secondaryAction={ onClose }
          primaryAction={ handleUpdate }
          disabled={ false }
        >
          <div className="cp_component_group_accounts_content">
            <br />
            <Input
              className="input_first_last_name"
              value={ firstName }
              onChange={ (e) => setFirstName(e.currentTarget.value) }
              fullWidth={ true }
              label={ translate("First Name") }
              placeholder="Enter First Name"
              isValueCaps={ false }
            />
            <br />
            <Input
              className="input_first_last_name"
              value={ lastName }
              onChange={ (e) => setLastName(e.currentTarget.value) }
              fullWidth={ true }
              label={ translate("Last Name") }
              placeholder="Enter Last Name"
              isValueCaps={ false }
            />
            <br />
            <Stack alignItems="center" direction="row" gap={ 1 }>
              <WebAssetIcon />
              <p className="label_small">{ translate("Website From Public Profile") }</p>
            </Stack>
            <p className="body_small">{ (account.websiteUrl) ? account.websiteUrl : "No website" }</p>
            <br />
            <Stack alignItems="center" direction="row" gap={ 1 }>
              <MailOutlineIcon />
              <p className="label_small">{ translate("Emails From Public Profile") }</p>
            </Stack>
            { (!account.emails || account.emails.length === 0) && (
              <p className="body_small">No emails available</p>
            ) }
            { account.emails.map((email) => (
              <p className="body_small">{ email.address }</p>
            )) }
            <br />
            <Stack alignItems="center" direction="row" gap={ 1 }>
              <MailOutlineIcon />
              <p className="label_small">{ translate("Emails Added By Your Team") }</p>
              <Button onClick={ editEmailsByYourTeam } isTextButton={ true } label="Edit" />
            </Stack>
            { (account.emailsSourcedFromTeam === null || account.emailsSourcedFromTeam.length === 0) && (
            <p className="body_small">No emails available</p>
            ) }
            { account.emailsSourcedFromTeam.map((email) => (
              <p className="body_small">{ email.address }</p>
            )) }
            <br />
            <Stack alignItems="center" direction="row" gap={ 1 }>
              <MailOutlineIcon />
              <p className="label_small">{ translate("CHOOSE A PRIMARY EMAIL ADDRESS") }</p>
            </Stack>
            <Dropdown
              buttonClassName="dropdown_primary_email"
              buttonType="custom"
              placement="top"
              customButtonChildren={ (
                <Button
                  className="dropdown_primary_email"
                  label={ (
                    <>
                      <span className="dropdown_primary_email">
                        { (primaryEmail !== "")
                          ? primaryEmail
                          : translate("Select Primary Email") }
                      </span>
                      <ArrowDropDown />
                    </>
                  ) }
                  isPrimary={ false }
                  variant="outlined"
                />
              ) }
            >
              <List disablePadding={ true }>
                { account.emails.map((email) => (
                  <ListItem disablePadding={ true } disableGutters={ true } key={ email.address }>
                    <ListItemButton onClick={
                      (event) => setPrimaryEmail((event.currentTarget.textContent) ? event.currentTarget.textContent : "")
                    }
                    >
                      <ListItemText primary={ email.address } className={ (email.address === primaryEmail) ? "selected" : "" } />
                    </ListItemButton>
                  </ListItem>
                )) }
                { account.emailsSourcedFromTeam.map((email) => (
                  <ListItem disablePadding={ true } disableGutters={ true } key={ email.address }>
                    <ListItemButton onClick={
                      (event) => setPrimaryEmail((event.currentTarget.textContent) ? event.currentTarget.textContent : "")
                    }
                    >
                      <ListItemText primary={ email.address } className={ (email.address === primaryEmail) ? "selected" : "" } />
                    </ListItemButton>
                  </ListItem>
                )) }
              </List>
            </Dropdown>
          </div>
        </Modal>
      )
    }

    // Default creation of the jsx elements
    return (
      <Modal
        className="cp_component_group_accounts_contact_info_modal"
        open={ open }
        customTitle={ <SocialAvatar { ...socialAvatarInfo } /> }
        closeAction={ onClose }
        primaryLabel={ translate("SAVE") }
        secondaryLabel={ translate("CLOSE") }
        secondaryAction={ onClose }
        primaryAction={ handleUpdate }
        disabled={ false }
      >
        <div className="cp_component_group_accounts_content">
          <br />
          <Input
            className="input_first_last_name"
            value={ firstName }
            onChange={ (e) => setFirstName(e.currentTarget.value) }
            fullWidth={ true }
            label={ translate("First Name") }
            placeholder="Enter First Name"
            isValueCaps={ false }
          />
          <br />
          <Input
            className="input_first_last_name"
            value={ lastName }
            onChange={ (e) => setLastName(e.currentTarget.value) }
            fullWidth={ true }
            label={ translate("Last Name") }
            placeholder="Enter Last Name"
            isValueCaps={ false }
          />
          <br />
          <Stack alignItems="center" direction="row" gap={ 1 }>
            <WebAssetIcon />
            <p className="label_small">{ translate("Website From Public Profile") }</p>
          </Stack>
          <p className="body_small">No website</p>
          <br />
          <Stack alignItems="center" direction="row" gap={ 1 }>
            <MailOutlineIcon />
            <p className="label_small">{ translate("Emails From Public Profile") }</p>
          </Stack>
          <p className="body_small">No emails available</p>
          <br />
          <Stack alignItems="center" direction="row" gap={ 1 }>
            <MailOutlineIcon />
            <p className="label_small">{ translate("Emails Added By Your Team") }</p>
            <Button onClick={ editEmailsByYourTeam } isTextButton={ true } label="Edit" />
          </Stack>
          <p className="body_small">No emails available</p>
          <br />
          <Stack alignItems="center" direction="row" gap={ 1 }>
            <MailOutlineIcon />
            <p className="label_small">{ translate("CHOOSE A PRIMARY EMAIL ADDRESS") }</p>
          </Stack>
          <Dropdown
            buttonClassName="dropdown_primary_email"
            buttonType="custom"
            placement="top"
            customButtonChildren={ (
              <Button
                className="dropdown_primary_email"
                label={ (
                  <>
                    <span className="dropdown_primary_email">
                      { (primaryEmail && primaryEmail !== "") ? primaryEmail : translate("Select Primary Email") }
                    </span>
                    <ArrowDropDown />
                  </>
                ) }
                isPrimary={ false }
                variant="outlined"
              />
            ) }
          >
            <List disablePadding={ true } />
          </Dropdown>
        </div>
      </Modal>
    )
  }

  // Return the rendered content
  return render()
}
