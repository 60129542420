/* eslint-disable react/no-unused-prop-types */
import React, { JSX } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import {
  DarkMode,
  DoNotDisturb,
  PendingActions,
  Unsubscribe,
} from "@mui/icons-material"

import * as GraphQL from "../../graphql"
import * as MiscHelper from "../../util/miscHelper"
import NetworkIcon from "../NetworkIcon"
import Tooltip from "../Tooltip"
import OauthedIcon from "../RowAdornments/OauthedIcon"
import { NSFWLabelWTooltip } from "../IconNSFW"
import { Scope } from "../../util/types"
import { useSelector } from "../../state/hooks"

import "./style.sass"

export type Props = {
  followers: number,
  fullName: string,
  isBlacklisted: boolean,
  isNSFW: boolean,
  isPlaceholder: boolean,
  isUnsubscribed: boolean,
  lastPostedDate?: number,
  network: GraphQL.Network,
  profilePictureUrl: string,
  username: string,
  oauthed?: boolean
}

function renderBlacklistedIcon(
  tooltipText: string,
  isBlacklisted: boolean,
): JSX.Element | null {
  if (!isBlacklisted) return null

  return (
    <Tooltip arrow={ true } placement="top" title={ tooltipText }>
      <DoNotDisturb
        className="cp_component_social-avatar-blacklisted-icon"
      />
    </Tooltip>
  )
}

function renderInactiveIcon(
  tooltipText: string,
  lastPostedDate?: number,
): JSX.Element | null {
  if (lastPostedDate == null) return null

  const daysAgo = moment().diff(moment.unix(lastPostedDate), "days")

  if (daysAgo < 90) return null

  return (
    <Tooltip arrow={ true } placement="top" title={ tooltipText }>
      <DarkMode
        className="cp_component_social-avatar-inactive-icon"
      />
    </Tooltip>
  )
}

function renderPlaceholderIcon(
  tooltipText: string,
  isPlaceholder: boolean,
): JSX.Element | null {
  if (!isPlaceholder) return null

  return (
    <Tooltip arrow={ true } placement="top" title={ tooltipText }>
      <PendingActions
        className="cp_component_social-avatar-placeholder-icon"
      />
    </Tooltip>
  )
}

function renderUnsubscribedIcon(
  tooltipText: string,
  isUnsubscribed: boolean,
): JSX.Element | null {
  if (!isUnsubscribed) return null

  return (
    <Tooltip arrow={ true } placement="top" title={ tooltipText }>
      <Unsubscribe
        className="cp_component_social-avatar-unsubscribed-icon"
      />
    </Tooltip>
  )
}

export default function SocialAvatar(props: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.AccountCell" })

  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)

  const {
    followers,
    fullName,
    isPlaceholder,
    network,
    profilePictureUrl,
    username,
  } = props

  const shorthandFollowers = MiscHelper.shorthandNumber(followers)
  const placeholderClassName = isPlaceholder
    ? "cp_component-social-avatar-placeholder"
    : ""

  return (
    <div className="cp_component_social-avatar">
      <aside style={ { backgroundImage: `url(${ profilePictureUrl })` } }>
        { network && (
          <NetworkIcon
            network={ network }
            isActive={ true }
          />
        ) }
      </aside>
      <section>
        <div>
          <p className={ placeholderClassName }>{ `@${ username }` }</p>
          <aside>
            {
              renderInactiveIcon(
                translate("No posts in the last 90 days."),
                props.lastPostedDate,
              )
            }
            {
              renderPlaceholderIcon(
                translate("This is a placeholder account."),
                props.isPlaceholder,
              )
            }
            <OauthedIcon tooltipText={ translate("This account is OAuthed.") } oauthed={ props.oauthed } />
            { scopes.includes(Scope.BLOCKLISTED_PROFILES)
              && renderBlacklistedIcon(
                translate("Influencer is blocklisted."),
                props.isBlacklisted,
              )
            }
            {
              renderUnsubscribedIcon(
                translate("Influencer has unsubscribed."),
                props.isUnsubscribed,
              )
            }
            <NSFWLabelWTooltip
              isNSFW={ props.isNSFW }
              labelClass="default"
            />
          </aside>
        </div>
        <p className="label_medium">{ fullName }</p>
        <p className="label_small-caps-bold">
          { `${ shorthandFollowers } ${ translate("Followers") }` }
        </p>
      </section>
    </div>
  )
}
